exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-contentful-articleroll-js": () => import("./../../../src/templates/contentful-articleroll.js" /* webpackChunkName: "component---src-templates-contentful-articleroll-js" */),
  "component---src-templates-contentful-author-page-js": () => import("./../../../src/templates/contentful-author-page.js" /* webpackChunkName: "component---src-templates-contentful-author-page-js" */),
  "component---src-templates-contentful-blogroll-js": () => import("./../../../src/templates/contentful-blogroll.js" /* webpackChunkName: "component---src-templates-contentful-blogroll-js" */),
  "component---src-templates-contentful-compose-page-js": () => import("./../../../src/templates/contentful-compose-page.js" /* webpackChunkName: "component---src-templates-contentful-compose-page-js" */),
  "component---src-templates-contentful-events-collection-js": () => import("./../../../src/templates/contentful-events-collection.js" /* webpackChunkName: "component---src-templates-contentful-events-collection-js" */),
  "component---src-templates-contentful-insights-js": () => import("./../../../src/templates/contentful-insights.js" /* webpackChunkName: "component---src-templates-contentful-insights-js" */),
  "component---src-templates-contentful-newsroll-js": () => import("./../../../src/templates/contentful-newsroll.js" /* webpackChunkName: "component---src-templates-contentful-newsroll-js" */),
  "component---src-templates-contentful-webinars-collection-js": () => import("./../../../src/templates/contentful-webinars-collection.js" /* webpackChunkName: "component---src-templates-contentful-webinars-collection-js" */)
}

